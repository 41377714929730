import { PropsWithChildren } from 'react';
import { Stack, TypographyProps } from '@mui/material';

import { Typography } from '@/libs/ui';

import Marker from './Marker';

interface BlockTitleProps {
  title: string;
  marker?: string;
  variant?: TypographyProps['variant'];
}

const BlockTitle = ({ children, title, marker, variant = 'h6' }: BlockTitleProps & PropsWithChildren) => {
  return (
    <Stack direction="row" gap={0.5}>
      <Typography variant={variant}>
        {title}
      </Typography>
      {children}
      {marker && <Marker text={marker} />}
    </Stack>
  );
};

export default BlockTitle;
