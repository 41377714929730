'use client';
import { useState } from 'react';
import { Box, Stack, Grid } from '@mui/material';
import { SentimentEnum } from '@/libs/types';
import isEmpty from 'lodash/isEmpty';

import { Typography, Button } from '@/libs/ui';
import PositiveSvg from '@/libs/icons/21/positive.svg';
import NegativeSvg from '@/libs/icons/21/negative.svg';
import CheckListSvg from '@/libs/icons/38/check-list.svg';

import BlockTitle from '../../BlockTitle';
import TickersList from './TickersList';
import { useTranslations } from 'next-intl';

interface ChartProps {
  data: Record<SentimentEnum, Record<string, number>>;
}

const Chart = ({ data }: ChartProps) => {
  const t = useTranslations();
  const [selected, setSelected] = useState<SentimentEnum>(SentimentEnum.POSITIVE);
  const isDataEmpty = isEmpty(data[SentimentEnum.NEGATIVE]) && isEmpty(data[SentimentEnum.POSITIVE]);
  
  return (
    <>
      <Stack direction="row" alignItems="center" gap={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8} md={12} lg={8} display="flex" flexDirection="row" alignItems="center" gap={1}>
            <Box>
              <CheckListSvg />
            </Box>
            <BlockTitle marker={t('Common.markerAi')} title={t('NewsSection.charts.cartTitleTopStocks')} />
          </Grid>
          {!isDataEmpty && (
            <Grid
              item
              xs={12}
              sm={4}
              md={12}
              lg={4}
              display="flex"
              gap={2}
              direction="row" 
              alignItems="center" 
              justifyContent="center"
            >
              <Button
                variant="text"
                size="small"
                disabled={selected === SentimentEnum.POSITIVE}
                onClick={() => setSelected(SentimentEnum.POSITIVE)}
              >
                <Typography variant="caption">
                  {t('Sentiments.POSITIVE')}
                </Typography>
              </Button>
              <Button
                variant="text"
                size="small"
                disabled={selected === SentimentEnum.NEGATIVE}
                onClick={() => setSelected(SentimentEnum.NEGATIVE)}
              >
                <Typography variant="caption">
                  {t('Sentiments.NEGATIVE')}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      <Box width="100%" paddingX={{ sx: 2, sm: 7, md: 2, lg: 7 }} paddingBottom={2}>
        {!isDataEmpty && (
          <Stack paddingTop={3} direction="row" gap={1} alignItems="center" justifyItems="center">
            <Typography variant="body1">
              {SentimentEnum.POSITIVE === selected
                ? t('NewsSection.charts.cartTitleTopStocksPositive')
                : t('NewsSection.charts.cartTitleTopStocksNegative')}
            </Typography>
            {SentimentEnum.POSITIVE === selected ? <PositiveSvg /> : <NegativeSvg />}
          </Stack>
        )}
        <TickersList selected={selected} data={data} />
      </Box>
    </>
  );
};

export default Chart;