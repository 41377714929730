import { Stack, Typography } from '@mui/material';
import { useTranslations } from 'next-intl';
import React from 'react';

const NoData = () => {
  const t = useTranslations('NewsSection.charts');

  return (
    <Stack height="100%" justifyContent="center" marginRight="50px">
      <Typography variant="body2">{t('chartNoData')}</Typography>
    </Stack>
  );
};

export default NoData;