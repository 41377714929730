'use client'
import { useState } from 'react';
import { Stack } from '@mui/material';
import { useTranslations } from 'next-intl';

import { CategoryEnum, CategorySectorEnum } from '@/libs/types';
import { useAnalysisSuspenseInfiniteQuery } from '@/libs/api';
import { Button } from '@/libs/ui';

import Card from './Card';

interface AnalysisListProps {
  symbol?: string;
  sector?: CategorySectorEnum;
  category?: CategoryEnum;
}

const AnalysisList = (params: AnalysisListProps) => {  
  const t = useTranslations()

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useAnalysisSuspenseInfiniteQuery(params);

  const handleMoreClick = () => {
    fetchNextPage();
  };
  
  return (
    <Stack gap={3}>
      {data.entities.map((item: any) => (
        <Card key={item.id} item={item} />
      ))}
      {hasNextPage && (
        <Button variant='text' fullWidth onClick={handleMoreClick} disabled={isFetchingNextPage}>
          {t('Common.more')}
        </Button>
       )}
    </Stack>
  );
};

export default AnalysisList;