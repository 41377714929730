'use client'
import { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';

import NoData from './NoData';
import { colors } from './constants';

interface BarChartProps {
  data: Array<[string, number]>;
}

const BarChart = ({ data }: BarChartProps) => {
  const max = Math.max(...data.map(([_, value]) => value));

  const [heights, setHeights] = useState(Array(data.length).fill(0));
  const [labelsVisible, setLabelsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHeights(data.map(([_, value]) => (value / max) * 100));
    }, 0);

    const labelTimer = setTimeout(() => {
      setLabelsVisible(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      clearTimeout(labelTimer);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max]);
  
  if (data.every(([_, value]) => !value)) {
    return (
      <NoData />
    );
  }
  
  return (
    <Stack direction="row" justifyContent="space-around" alignItems="flex-end" gap={1} height="100%">
      {data.map(([label, value], index) => value ? (
        <Box
          key={index}
          sx={{
            width: 44,
            minHeight: 15,
            height: `${heights[index]}%`,
            bgcolor: colors[label],
            borderRadius: 4,
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            transition: 'height 0.3s ease-in-out',
            fontSize: '0.75rem',
            '&:hover': {
              borderWidth: 1,
              borderColor: '#fff',
            }
          }}
        >
          {labelsVisible && (
            <Box paddingTop={heights[index] > 15 ? 1 : 0 } marginY={heights[index] > 15 ? 0 : 'auto'}>
              {labelsVisible ? `${value}%` : ''}
            </Box>
          )}
        </Box>
      ) : null)}
    </Stack>
  );
};

export default BarChart;
