'use client';
import { useTranslations } from "next-intl";
import { Button } from "@/libs/ui";
import { useNewsSuspenseInfiniteQuery } from '@/libs/api';
import NewsCard from "./NewsCard";
import { FilterProps } from "@/libs/types";

const News = (params: FilterProps) => {
  const t = useTranslations();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useNewsSuspenseInfiniteQuery(params);

  const handleMoreClick = () => {
    fetchNextPage();
  };

  return (
    <>
      {data?.entities.map((item: any) =>
        <NewsCard key={item.id} item={item} />
      )}
      {hasNextPage && (
        <Button variant="text" fullWidth onClick={handleMoreClick} disabled={isFetchingNextPage}>
          {t('Common.more')}
        </Button>
      )}
    </>
  );
};

export default News;
