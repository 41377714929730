'use client';
import { Chart as ChartJS, ArcElement, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

import { colors } from './constants';
import NoData from './NoData';

ChartJS.register(ArcElement, ChartDataLabels);

const bgColors = Object.values(colors).map((color) => color);

export interface DoughnutChartProps {
  data: Array<[string, number]>;
  chartOptions?: ChartOptions<'doughnut'>;
}

const options: ChartOptions<'doughnut'> = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: 'left',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
      },
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      color: '#000',
      formatter: (value) => {          
        return +value ? `${value}%` : null;
      }
    }
  },
};

const DoughnutChart = ({ data, chartOptions }: DoughnutChartProps) => {   
  if (data.every(([_, value]) => !value)) {
    return (
      <NoData />
    );
  }
    
  return (
    <Doughnut
      data={{
        labels: data.map(([label]) => label),
        datasets: [
          {
            data: data.map(([, value]) => value),
            backgroundColor: bgColors,
            hoverBackgroundColor: bgColors,
            borderWidth: 1,
            borderColor: '#fff',
            spacing: 4,
            borderRadius: 10
          }
        ]
      }}
      options={{ ...options, ...chartOptions }}
    />
  );
};

export default DoughnutChart;
