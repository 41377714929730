'use client';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title, ChartOptions } from 'chart.js';

import { NewsSortEnum } from '@/libs/types';
import { colors } from '../constants';
import { ChartData } from './types';
import { useTranslations } from 'next-intl';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Title);

interface LineChartProps {
  data: ChartData;
  sort: NewsSortEnum;
}

const currentYear = new Date().getFullYear();

const LineChart = ({ data, sort }: LineChartProps) => {
  const t = useTranslations();

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        label: t('Sentiments.POSITIVE'),
        data: data.positive,
        borderColor: colors.POSITIVE,
        backgroundColor: colors.POSITIVE,
        tension: 0.4,
        pointRadius: 1,
      },
      {
        label: t('Sentiments.NEGATIVE'),
        data: data.negative,
        borderColor: colors.NEGATIVE,
        backgroundColor: colors.NEGATIVE,
        tension: 0.4,
        pointRadius: 1,
      },
    ]
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#333',
        titleColor: '#fff',
        bodyColor: '#fff',
        titleFont: { size: 15 },
        bodyFont: { size: 14 },
        callbacks: {
          title: (context) => {
            const label = context[0].label;
  
            const monthIndex = data.labels.indexOf(label);
            const decemberIndex = data.labels.indexOf('Dec');
            
            if (sort === NewsSortEnum.YEAR) {
              return label;
            }

            return `${label} ${decemberIndex >= monthIndex ? currentYear : currentYear - 1}`;
          },
          label: (context) => {
            return `${context.parsed.y}: ${context.dataset.label}`;
          }
        }
      }
    }
  };

  return (
    <Line data={chartData} options={options} />
  );
};

export default LineChart;
