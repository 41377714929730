import Box from '@mui/material/Box';

import { Typography } from '../../ui/Typography';

interface MarkerProps {
  text: string;
}

const Marker = ({ text }: MarkerProps) => {
  return (
    <Box
      width="20px"
      height="20px"
      borderRadius="7px"
      bgcolor="#D5E26E"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography fontWeight={600} variant="caption">{text}</Typography>
    </Box>
  );
};

export default Marker;