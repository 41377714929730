'use client'
import { usePathname, useSearchParams , useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';

import { Button, Typography, useDispatchPageLoader } from '@/libs/ui';
import { Stack } from '@mui/material';
import React from 'react';
import { NewsSortEnum } from '@/libs/types';

const NEWS_SORT: Record<NewsSortEnum, string> = {
  [NewsSortEnum.DAY]: 'day',
  [NewsSortEnum.WEEK]: 'week',
  [NewsSortEnum.MONTH]: 'month',
  [NewsSortEnum.YEAR]: 'year',
}

interface SortProps {
  sort: NewsSortEnum;
}

const Sort = ({ sort }: SortProps) => {
  const t = useTranslations('NewsSection.filter');

  const setLoader = useDispatchPageLoader();

  const searchParams = useSearchParams() ?? '';
  const pathname = usePathname();
  const router = useRouter();

  const params = new URLSearchParams(searchParams);
 
  const onSortChange = (sort: string) => {
    setLoader(true);

    params.set('sort', sort);

    router.push(`${pathname}?${params.toString()}`);
  }
  
  return (
    <Stack
      direction="row"
      marginLeft={{
        xs: 0,
        sm: "auto",
        md: 0,
        lg: "auto",
      }}
      gap={3}
      >
      {(Object.keys(NewsSortEnum) as Array<keyof typeof NewsSortEnum>).map((key) => {
        const value = t(NEWS_SORT[key]);

        return (
          <Button
            key={key}
            disabled={sort === NewsSortEnum[key]}
            variant="text"
            size="small"
            onClick={() => onSortChange(key)}
          >
            <Typography variant="caption">
              {value}
            </Typography>
          </Button>
        );
      })}
    </Stack>
  );
};

export default Sort;