import React from 'react';
import { SentimentEnum } from '@/libs/types';
import { Box, Stack, LinearProgress } from '@mui/material';
import { Typography, Tooltip } from '@/libs/ui';
import { useTranslations } from 'next-intl';

interface TickersListProps {
  data: Record<SentimentEnum, Record<string, number>>;
  selected: SentimentEnum;
}

const TickersList = ({ selected, data }: TickersListProps) => {
  const t = useTranslations('NewsSection.charts');
  const selectedData = Object.entries(data[selected]);

  if (!selectedData.length) {
    return (
      <Stack height="100%" width="100%" paddingY={8} textAlign="center">
        <Typography variant="body2">
          {t('chartNoData')}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack width="100%" paddingY={1} gap={1}>
      {selectedData.map(([symbol, value]) => {
        const fixedValue = Number(value.toFixed(2));

        return (
          <Stack key={`LineChartHorizontal-${selected}-${symbol}-${value}`} direction="row" alignItems="center" gap={1}>
            <Typography variant="body2" width="20%">
              {symbol}
            </Typography>
            <Tooltip
              title={selected === SentimentEnum.POSITIVE
                ? t('cartTooltipTopStocksPositive', { symbol, value: fixedValue })
                : t('cartTooltipTopStocksNegative', { symbol, value: fixedValue })}
              placement="top"
            >
              <Box width="100%" paddingY={1}>
                <LinearProgress
                  sx={{ 
                    backgroundColor: '#E9EAEB',
                    borderRadius: '100px',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: selected === SentimentEnum.POSITIVE ? '#BEE8BE' : '#F8A4A1',
                    },
                  }}
                  variant="determinate"
                  value={fixedValue}
                />
              </Box>
            </Tooltip>
          </Stack>
        )
      })}
    </Stack>
  );
};

export default TickersList;