import { Card as MuiCard, Box } from '@mui/material';

import { Analysis } from '@/libs/types';
import { styled } from '@/libs/ui';

export const TrendDot = styled(Box)<{ trend: Analysis['sentiment'] }>(({ theme, trend }) => ({
  width: 6,
  height: 6,
  borderRadius: '50%',
  // @ts-ignore
  backgroundColor: theme.palette.trend[trend.toLowerCase()],
}));

export const CardComponent = styled(MuiCard)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: 'none',
  borderRadius: '30px',
  border: `1px solid ${theme.palette.divider}`,
}));

