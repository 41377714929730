import React from 'react';
import { isToday, format, parseISO } from 'date-fns';
import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { CardContent, Typography, Box, LinearProgress, Stack, Chip } from '@mui/material';

import { formatRelativeDate } from '@/libs/utils';
import { Analysis } from '@/libs/types';

import { CardComponent, TrendDot } from './styled';
import Description from './Description';

interface AnalysisCardProps {
  item: Analysis;
}

const AnalysisCard = ({ item }: AnalysisCardProps) => {
  const t = useTranslations();

  return (
    <CardComponent>
      <CardContent>
        <Stack direction="row" alignItems="center" paddingBottom={3}>
          <Typography variant="h5">
            {isToday(item.relatedDate) ? t('AnalysisSection.cardTodayDate') : format(parseISO(item.relatedDate.split('T')[0]), 'dd MMMM yyyy')}
          </Typography>
          {isToday(new Date(item.relatedDate)) && (
            <Box paddingLeft={2.5}>
              <Typography variant="body1" color="textSecondary">
                {formatRelativeDate(item.relatedDate, 'p')}
              </Typography>
            </Box>
          )}
          <Stack direction="row" alignItems="center" marginLeft="auto" gap={3}>
            <Stack direction="row" alignItems="center" gap={1}>
              <TrendDot trend={item.sentiment} />
              <Typography variant="caption">
                {t(`Sentiments.${item.sentiment}`)}
              </Typography>
            </Stack>
           
            <Stack direction="column" alignItems="center">
              <Typography variant="caption">
                {t('AnalysisSection.cardImportance', { importance: item.importance })}
              </Typography>
              <Box width="100%">
                <LinearProgress variant="determinate" value={item.importance * 10} />
              </Box>
            </Stack>
          </Stack>
        </Stack>
       
        <Description content={item.analysis} />
       
        <Stack paddingTop={3} direction="row" gap={1} flexWrap="wrap">
          {!!item.category && (
            <Link 
              key={`category-link-${item.category}-${item.id}`}
              href={"/"} 
              passHref
            >
              <Chip
                label={t('AnalysisSection.cardChipCategory', { name: t(`Categories.${item.category}`) })}
                size="medium"
                clickable
              />
            </Link>
          )}
          {!!item.sector && (
            <Link 
              key={`sector-link-${item.sector}-${item.id}`}
              href={`/sectors/${item.sector}`} 
              passHref
            >
              <Chip
                label={t('AnalysisSection.cardChipSector', { name: t(`Sectors.${item.sector}`) })}
                size="medium"
                clickable
              />
            </Link>
          )}
          {item.tyckerSymbol && (
            <Link 
              href={`/tickers/${item.tyckerSymbol}`} 
              passHref
            >
              <Chip 
                label={t('AnalysisSection.cardChipTicker', { name: item.tyckerSymbol })}
                size="medium"
                clickable
              />
            </Link>
          )}
        </Stack>
      </CardContent>
    </CardComponent>
  );
};

export default AnalysisCard;
