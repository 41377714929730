import React, { useState } from 'react';

import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';

import { Typography, Link } from '@/libs/ui';
import { useTranslations } from 'next-intl';

const CHAR_LIMIT = 200;

type DescriptionProps = {
  content: string;
};

const Description = ({ content }: DescriptionProps) => {
  const t = useTranslations('AnalysisSection');
  const [isExpanded, setIsExpanded] = useState(false);

  const displayContent = isExpanded || content.length <= CHAR_LIMIT ? content : `${content.substring(0, CHAR_LIMIT)}...`;

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Typography variant="body1" color="text.secondary">
      {displayContent}
      {content.length > CHAR_LIMIT && (
        <Link
          paddingLeft={1}
          underline="none"
          variant='body1'
          onClick={toggleExpand}
        >
          {isExpanded ? t('cardLess') : t('cardMore')}
          {isExpanded ? (
            <NorthEastIcon sx={{ fontSize: '1.2rem', paddingTop: '8px' }}/>
          ) : (
            <SouthEastIcon sx={{ fontSize: '1.2rem', paddingTop: '8px' }}/>
          )}
        </Link>
      )}
    </Typography>
  );
};

export default Description;
