import { Box, Divider, Stack } from "@mui/material";
import { News as NewsModel } from "@/libs/types";

import { Link, Typography } from '@/libs/ui';
import ClockSvg from '@/libs/icons/14/clock.svg';

import NewsTime from './Time';
import { useTranslations } from "next-intl";

interface NewsCardProps {
  item: NewsModel;
}

const NewsCard = ({ item }: NewsCardProps) => {
  const t = useTranslations();
  
  return (
    <Box paddingY={2}>
      <Stack alignItems="center" direction="row" paddingBottom={2}>
        <ClockSvg />
        <NewsTime publishTime={item.publishTime} />
      </Stack>

      <Typography variant="body1">{item.title}</Typography>

      <Stack direction="row" paddingY={2} gap={1}>
        {/* <Link
          underline="none"
          variant='body1'
          onClick={() => {}}
        >
          Read more
        </Link>
        <Divider orientation="vertical" flexItem /> */}
        {item.link && (
          <Link
            underline="none"
            variant='body1'
            onClick={() => item.link && global.open(item.link, '_blank')}
          >
            {t('Common.link')}
          </Link>
        )}
      </Stack>
      <Divider />
    </Box>
  );
};

export default NewsCard;