'use client'
import React, { useEffect, useState } from 'react';

import { formatRelativeDate } from "@/libs/utils";
import { Typography } from '@/libs/ui';

interface TimeProps {
  publishTime: string;
}

const NewsTime = ({ publishTime }: TimeProps) => {
  const [dateString, setDateString] = useState('');

  useEffect(() => {
    setDateString(formatRelativeDate(publishTime, 'dd MMMM yyyy, p', 'America/New_York'));
  }, [publishTime]);

  return (
    <Typography variant="caption" sx={{ ml: 0.5 }}>
      {dateString}
    </Typography>
  );
};

export default NewsTime;